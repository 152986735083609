<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
        class="qrcode-wrap"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row>
        <v-col
          :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
          :cols="$store.getters.getMobileModel !== 'PDA' ? 6 : 7"
        >
          <v-text-field
            id="input-qrkey"
            v-model="qrKey"
            label="QR 스캔"
            autocomplete="off"
            placeholder="QR 스캔"
            :disabled="isDisabled"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-7 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
        <v-col
          cols="5"
        >
          <v-text-field
            id="input-qty"
            v-model="inputQty"
            autocomplete="off"
            label="실사수량"
            type="number"
            :disabled="isDisabled"
            @keyup.enter.prevent="onEnterQty"
          />
        </v-col>
      </v-row>
      <v-text-field
        v-model="selectedRow.품번"
        label="품번"
        autocomplete="off"
        :readonly="true"
        filled
      />
      <v-text-field
        v-model="selectedRow.품명"
        label="품명"
        autocomplete="off"
        :readonly="true"
        filled
      />
      <div style="margin-top:10px; background-color:white;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="inventoryInfoList"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeightSub"
          :focused-row-enabled="true"
          key-expr="seqId"
          :focused-row-key.sync="focusedRowKey"
          @row-removed="onRowRemoved"
        >
          <DxEditing
            :allow-deleting="true"
            :allow-updating="false"
            :allow-adding="false"
            :use-icons="true"
            mode="row"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
          />
          <DxColumn
            :allow-editing="false"
            caption="일련번호"
            data-field="입고키"
            sort-order="desc"
          />
          <DxColumn
            :allow-editing="false"
            caption="실사수량"
            data-field="수량"
            format="#,##0"
          />
          <DxColumn
            width="50"
            :buttons="editButtons"
            type="buttons"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
    </v-container>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, { DxScrolling, DxColumn, DxEditing, DxPaging, DxSorting, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import AppLib from '../share/AppLib'
import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
import QrCodeScanComponent from '../components/QrCodeScanComponent'

export default {
  name: 'PurchaseDelivery',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSummary,
    DxTotalItem,
    DxEditing,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      systemParam: null,
      deliveryListInfo: [],
      focusedRowKey: null,
      selectedRow: {},
      qrKey: null,
      inputQty: null,
      qrPCode: null,
      gridHeightSub: null,
      inventoryInfoList: [],
      editButtons: ['delete'],
      inventoryPlan: null,
      isDisabled: false,
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.systemParam = this.$store.getters.getSysParam
    this.refreshInventory()
  },
  mounted () {
    this.gridHeightSub = (this.$Q('html').height() - 270)
    window.addEventListener('resize', this.handleResize)
    this.initInput()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize (event) {
    },
    async addInventory () {
      const temp = {
        실사기준일: this.inventoryPlan[0].실사기준일,
        구분: 1, // 1: 재공실사 0:창고실사
        품번: this.qrPCode,
        수량: parseInt(this.inputQty),
        입고키: this.qrKey,
        createid: this.userInfo.userId,
        updateid: this.userInfo.userId,
        품명: this.selectedRow.품명
      }
      console.log(temp)
      this.$_sp.runInsertAndFindSqlProc('재고실사', ['품번', 'createid'], ['품명'], temp)
        .then((data) => {
          this.isLoading = false
          const result = this.$_sp.MakeModel(data)
          console.log(result)
          temp.seqId = result[0].seqId
          this.inventoryInfoList.push(temp)
          this.focusedRowKey = temp.seqId
          this.initInput()
        })
    },
    initInput () {
      this.qrKey = null
      this.inputQty = null
      this.selectedRow = {}
      setTimeout(() => {
        this.$Q('#input-qrkey').focus()
      }, 10)
    },
    async onEnterPcode (e) {
      if (this.qrKey === null || this.qrKey.length === 0) {
        return
      }
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrKey).split('/')
      if (split.length === 1) {
        this.$snotify.error('일련번호를 확인할 수 없습니다.')
        return
      }
      this.qrKey = parseInt(split[1])
      this.qrPCode = split[0].replace('?', ' ')
      this.isLoading = true
      console.log(AppLib.formatDateTimeFromTimeStamp(this.inventoryPlan[0].실사기준일))
      await this.$_sp.runNoEncodeFindSqlProc('재고실사', { 입고키: this.qrKey, 실사기준일: this.inventoryPlan[0].실사기준일 })
        .then((data) => {
          if (this.$_sp.MakeModel(data).length > 0) {
            this.isLoading = false
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })

      if (this.isLoading === false) {
        this.$snotify.error('해당 폼번/일련번호는 이미 재고 실사 등록이 되어 있습니다.')
        this.initInput()
        return
      }
      this.$_sp.runNoEncodeFindSqlProc('품목정보', { 품번: this.qrPCode })
        .then((data) => {
          this.isLoading = false
          const result = this.$_sp.MakeModel(data)
          console.log(result)
          if (result.length === 1) {
            if (result[0].품목유형 === ConstDef.구매품 && result[0].자재유형 !== ConstDef.외주직납품) {
              this.$snotify.info('해당 품목은 완/반제품이 아닙니다.')
              this.initInput()
            } else {
              this.selectedRow = result[0]
              console.log(this.selectedRow)
              this.showQrScaner = 'none'
              setTimeout(() => {
                this.$Q('#input-qty').focus()
              }, 10)
            }
          } else {
            this.$snotify.info('해당 품목을 검색 할 수 없습니다.')
            this.initInput()
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      this.qrKey = result
      this.onEnterPcode(null)
    },
    onEnterQty (e) {
      if (this.inputQty === undefined || this.inputQty === null || !AppLib.isNumeric(this.inputQty)) {
        this.$snotify.info('실사 수량을 입력하여 주세요.')
        this.inputQty = null
        return
      }
      if (this.qrPCode === null || this.qrPCode.length === 0) {
        this.$snotify.error('품목 정보를 스캔하여 주세요')
        return
      }
      this.$Q('#input-qrkey').focus()
      this.addInventory()
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('재고실사', [{ seqId: row.data.seqId }])
        .then((data) => {
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    async refreshInventory () {
      this.isLoading = true
      await this.$_sp.runFindSqlProc('재고실사계획', { 완료: 'N' })
        .then((data) => {
          this.inventoryPlan = this.$_sp.MakeModel(data)
          if (this.inventoryPlan.length === 0) {
            this.isLoading = false
            this.isDisabled = true
          }
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      if (this.isLoading === false) {
        this.$snotify.error('진행중인 실사 계획이 없습니다.')
        return
      }
      this.$_sp.runFindProc('spFindAllInventoryByDateAndUserIdAndTypeAndKeyNotNull', { 실사기준일: this.inventoryPlan[0].실사기준일, createid: this.userInfo.userId, 구분: 1 })
        .then((data) => {
          this.isLoading = false
          this.inventoryInfoList = this.$_sp.MakeModel(data)
          setTimeout(() => {
            this.$Q('#input-qrkey').focus()
            console.log('#input-qrkey focus')
          }, 400)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
